/* eslint-disable react/prop-types */
import React from 'react'
import { pipe, map, take, path, pathOr } from 'ramda'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import { flattenNodes } from '../../utils'
import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  AggregatorTemplate,
  Typography,
  Tabs,
  PinBlockGroup,
  AggregatorPin4,
  TransitionLink,
  ReadMore,
  MdxWrapper,
  AggregatorPin,
} from '../../components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import TabList from '../../components/elements/Tabs/TabList'
import Tab from '../../components/elements/Tabs/Tab'
import TabPanel from '../../components/elements/Tabs/TabPanel'
import styles from './AboutUsPageTemplate.module.css'

export const AboutUsPageTemplate = ({ data }) => {
  const { page, categories } = data
  const { frontmatter } = page
  const { intro, editor } = frontmatter

  const cats = map(path(['node', 'frontmatter', 'title']), categories.edges)
  const profiles = flattenNodes(data.profiles.edges)

  const order = ['Core Team', 'Co-Course Convenors', 'Consultants']
  const remainingCats = cats.filter(c => !order.includes(c))
  const orderedCats = [...order, ...remainingCats]

  return (
    <MDXProvider>
      <AggregatorTemplate breadcrumbs={[{ label: 'About Us' }]} intro={intro}>
        <Divider className={styles.sectionDivider} thickness={4} />

        <MdxWrapper>
          <MDXRenderer>{editor}</MDXRenderer>
        </MdxWrapper>

        <Divider className={styles.sectionDivider} thickness={4} />

        <Typography tag="h3" type="boxHeading" className="mb-4">
          Our Team
        </Typography>

        <Tabs initialValue={`tab-0`}>
          <TabList>
            {orderedCats.map((cat, i) => {
              const catProfiles = profiles.filter(p =>
                pathOr([], ['teamCategory'], p).includes(cat),
              )
              return (
                catProfiles.length > 0 && (
                  <Tab identifier={`tab-${i}`} key={i} kind="button">
                    {cat}
                  </Tab>
                )
              )
            })}
          </TabList>
          {orderedCats.map((cat, i) => {
            const catProfiles = profiles.filter(p =>
              pathOr([], ['teamCategory'], p).includes(cat),
            )
            return (
              catProfiles.length > 0 && (
                <TabPanel identifier={`tab-${i}`} key={i}>
                  <PinBlockGroup key={i} columns={2}>
                    {pipe(
                      take(4),
                      map((profile, i) => (
                        <AggregatorPin4
                          key={i}
                          title={profile.title}
                          thumbnailSrc={profile.featuredImage}
                          href={profile.slug}
                          className={styles.teamPin}
                        >
                          {profile.additionalInfo && (
                            <strong>{profile.additionalInfo}</strong>
                          )}
                          <p>{profile.excerpt}...</p>
                        </AggregatorPin4>
                      )),
                    )(catProfiles)}
                  </PinBlockGroup>
                </TabPanel>
              )
            )
          })}
        </Tabs>

        <ReadMore>
          <TransitionLink to="/about-us/team">View More</TransitionLink>
        </ReadMore>

        <Divider thickness={4} />

        <Typography tag="h3" type="boxHeading" className="mb-4">
          Our Resources
        </Typography>

        {/* <Typography tag="h4" type="h3">
          Podcasts, Glossary & FAQs
        </Typography> */}

        <PinBlockGroup columns={4}>
          <AggregatorPin
            imageUrl="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/Resources_Research.jpg"
            text="Podcasts"
          >
            <TransitionLink
              to="/about-us/our-resources"
              className="border-white text-white"
            >
              <span>Listen</span>
            </TransitionLink>
          </AggregatorPin>
          <AggregatorPin
            title="View Publications"
            imageUrl="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963654/website/misc/researching.png"
            href="/researching/"
            text="Publications"
          >
            <TransitionLink
              to="/researching/"
              className="border-white text-white"
            >
              <span>View</span>
            </TransitionLink>
          </AggregatorPin>
          <AggregatorPin
            title="Visit Press Page"
            imageUrl="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/PressOffice.jpg"
            href="/about-us/press-office/"
            text="Press Office"
          >
            <TransitionLink
              to="/about-us/press-office/"
              className="border-white text-white"
            >
              <span>View</span>
            </TransitionLink>
          </AggregatorPin>
          <AggregatorPin
            title="Visit Opportunities"
            imageUrl="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/Catalysing_Systems.jpg"
            href="/catalysing/category/opportunities/"
            text="Opportunities"
          >
            <TransitionLink
              to="/catalysing/category/opportunities/"
              className="border-white text-white"
            >
              <span>View</span>
            </TransitionLink>
          </AggregatorPin>
          {/* <AggregatorPin3
            title="Podcasts"
            thumbnailSrc="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/Resources_Research.jpg"
            href="/about-us/our-resources"
          /> */}
          {/* <AggregatorPin4
            title="FAQs"
            thumbnailSrc="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/Catalysing_Finance.jpg"
          >
            Short summary of this area or sector of work and mention its
            presence in the media. 20 - 30 words only and truncates with an
            ellipses ...
          </AggregatorPin4>
          <AggregatorPin4
            title="Glossary"
            thumbnailSrc="https://res.cloudinary.com/do95jfmcf/image/upload/v1586963655/website/misc/Upcoming-Courses.jpg"
          >
            Short summary of this area or sector of work and mention its
            presence in the media. 20 - 30 words only and truncates with an
            ellipses ...
          </AggregatorPin4> */}
        </PinBlockGroup>
      </AggregatorTemplate>
    </MDXProvider>
  )
}

AboutUsPageTemplate.propTypes = {}

const AboutUsPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <AboutUsPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

AboutUsPage.propTypes = {
  page: PropTypes.shape({
    frontmatter: PropTypes.shape({
      header: headerProps,
      editor: PropTypes.any,
    }),
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  ),
  profiles: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.object,
      }),
    ),
  }),
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageTemplate($slug: String!) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
        editor
      }
    }
    profiles: allMdx(
      filter: { fields: { contentType: { eq: "teamProfiles" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            additionalInfo
            teamCategory
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(
                  width: 200
                  transformations: "w_200,h_200,c_thumb,g_face"
                ) {
                  aspectRatio
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
    categories: allMdx(
      filter: { fields: { contentType: { eq: "teamCategories" } } }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`
